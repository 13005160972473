var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"form-wrapper"},[(_vm.showForm)?_c('div',[_vm._m(0),_c('van-form',{ref:"form",staticClass:"form"},[_c('div',{staticClass:"tip"},[_c('van-notice-bar',{attrs:{"color":"#1989fa","background":"#ecf9ff","text":"申请人信息"}})],1),_c('van-field',{attrs:{"readonly":"","disabled":"","name":"applyName","label":"姓名","placeholder":"请输入姓名","required":""},model:{value:(_vm.form.applyName),callback:function ($$v) {_vm.$set(_vm.form, "applyName", $$v)},expression:"form.applyName"}}),_c('van-field-select',{attrs:{"options":_vm.idCardTypes,"fieldProps":{
          label: '证件类型',
          required: true,
          readonly: true,
          disabled: true,
          placeholder: '请选择证件类型',
        }},model:{value:(_vm.form.idCardType),callback:function ($$v) {_vm.$set(_vm.form, "idCardType", $$v)},expression:"form.idCardType"}}),_c('van-field',{attrs:{"readonly":"","disabled":"","name":"idCardNum","label":"证件号码","placeholder":"请输入证件号码","required":""},model:{value:(_vm.form.idCardNum),callback:function ($$v) {_vm.$set(_vm.form, "idCardNum", $$v)},expression:"form.idCardNum"}}),_c('van-field',{attrs:{"name":"phone","label":"手机号","placeholder":"请输入手机号","required":"","maxlength":"11"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('van-field-select',{attrs:{"options":_vm.applyPeopleTypes,"fieldProps":{
          label: '申请人身份',
          required: true,
          placeholder: '请选择申请人身份',
        }},model:{value:(_vm.form.applyIdentify),callback:function ($$v) {_vm.$set(_vm.form, "applyIdentify", $$v)},expression:"form.applyIdentify"}}),_c('van-field',{attrs:{"label":"产业人才类别","placeholder":"请输入产业人才类别","required":"","readonly":"","disabled":""},model:{value:(_vm.form.industryTalentType),callback:function ($$v) {_vm.$set(_vm.form, "industryTalentType", $$v)},expression:"form.industryTalentType"}}),_c('div',{staticClass:"tip"},[_c('van-notice-bar',{attrs:{"color":"#1989fa","background":"#ecf9ff","text":"入学子女信息"}})],1),_c('van-field',{attrs:{"label":"姓名","placeholder":"请输入入学子女姓名","required":""},model:{value:(_vm.form.childrenName),callback:function ($$v) {_vm.$set(_vm.form, "childrenName", $$v)},expression:"form.childrenName"}}),_c('van-field-select',{attrs:{"options":_vm.sexTypes,"fieldProps":{
          label: '性别',
          required: true,
          placeholder: '请选择入学子女性别',
        }},model:{value:(_vm.form.childrenGender),callback:function ($$v) {_vm.$set(_vm.form, "childrenGender", $$v)},expression:"form.childrenGender"}}),_c('van-field-select',{attrs:{"options":_vm.nations,"fieldProps":{
          label: '民族',
          required: true,
          placeholder: '请选择民族',
        }},model:{value:(_vm.form.childrenNation),callback:function ($$v) {_vm.$set(_vm.form, "childrenNation", $$v)},expression:"form.childrenNation"}}),_c('van-field-calendar',{attrs:{"calendarProps":{
          mode: 'single',
        },"fieldProps":{
          required: true,
          label: '出生年月日',
          placeholder: '请输入出生年月日',
        },"required":""},model:{value:(_vm.form.childrenbirth),callback:function ($$v) {_vm.$set(_vm.form, "childrenbirth", $$v)},expression:"form.childrenbirth"}}),_c('van-field',{attrs:{"label":"身份证号","placeholder":"请输入身份证号","required":""},model:{value:(_vm.form.childrenIdCard),callback:function ($$v) {_vm.$set(_vm.form, "childrenIdCard", $$v)},expression:"form.childrenIdCard"}}),_c('van-field-select',{attrs:{"options":_vm.schoolList,"fieldProps":{
          label: '意愿学校',
          required: true,
          placeholder: '请选择意愿学校',
        }},model:{value:(_vm.form.applySchool),callback:function ($$v) {_vm.$set(_vm.form, "applySchool", $$v)},expression:"form.applySchool"}}),_c('van-field-area',{attrs:{"fieldProps":{
          required: true,
          label: '原就读学校所在区',
          placeholder: '请选择原就读学校所在区',
        }},model:{value:(_vm.form.oldSchoolArea),callback:function ($$v) {_vm.$set(_vm.form, "oldSchoolArea", $$v)},expression:"form.oldSchoolArea"}}),_c('van-field',{attrs:{"label":"原就读学校名称","placeholder":"请输入原就读学校名称","required":""},model:{value:(_vm.form.oldSchool),callback:function ($$v) {_vm.$set(_vm.form, "oldSchool", $$v)},expression:"form.oldSchool"}}),_c('van-field-area',{attrs:{"fieldProps":{
          required: true,
          label: '户籍所在地',
          placeholder: '户籍所在地',
        }},model:{value:(_vm.form.domicile),callback:function ($$v) {_vm.$set(_vm.form, "domicile", $$v)},expression:"form.domicile"}}),_c('van-field',{attrs:{"label":"户籍派出所","placeholder":"请输入户籍派出所","required":""},model:{value:(_vm.form.domicilePolice),callback:function ($$v) {_vm.$set(_vm.form, "domicilePolice", $$v)},expression:"form.domicilePolice"}}),_c('van-field-area',{attrs:{"fieldProps":{
          required: true,
          label: '家庭所在辖区',
          placeholder: '请输入家庭所在辖区',
        }},model:{value:(_vm.form.homeArea),callback:function ($$v) {_vm.$set(_vm.form, "homeArea", $$v)},expression:"form.homeArea"}}),_c('van-field',{attrs:{"label":"辖区办事处（乡）","placeholder":"请输入辖区办事处（乡）","required":""},model:{value:(_vm.form.homeAreaXiang),callback:function ($$v) {_vm.$set(_vm.form, "homeAreaXiang", $$v)},expression:"form.homeAreaXiang"}}),_c('van-field',{attrs:{"label":"社区居（村）委会","placeholder":"请输入社区居（村）委会","required":""},model:{value:(_vm.form.homeAreaCun),callback:function ($$v) {_vm.$set(_vm.form, "homeAreaCun", $$v)},expression:"form.homeAreaCun"}}),_c('van-field',{attrs:{"label":"住址所在小区名称","placeholder":"请输入住址所在小区名称","required":""},model:{value:(_vm.form.community),callback:function ($$v) {_vm.$set(_vm.form, "community", $$v)},expression:"form.community"}}),_c('van-field-area',{attrs:{"fieldProps":{
          required: true,
          label: '市内家庭所在区',
          placeholder: '请选择市内家庭所在区',
        }},model:{value:(_vm.form.famliyArea),callback:function ($$v) {_vm.$set(_vm.form, "famliyArea", $$v)},expression:"form.famliyArea"}}),_c('van-field',{attrs:{"label":"市内家庭详细住址","placeholder":"请输入市内家庭详细住址","required":""},model:{value:(_vm.form.famliyAreaDetail),callback:function ($$v) {_vm.$set(_vm.form, "famliyAreaDetail", $$v)},expression:"form.famliyAreaDetail"}}),_c('div',{staticClass:"tip"},[_c('van-notice-bar',{attrs:{"color":"#1989fa","background":"#ecf9ff","text":"入学子女简历"}})],1),_c('div',_vm._l((_vm.form.schoolHistory),function(item,i){return _c('div',{key:i},[_c('van-field-select',{attrs:{"options":_vm.schoolTypes,"fieldProps":{
              label: '学习阶段',
              required: true,
              placeholder: '请选择学习阶段',
            }},model:{value:(item.schoolType),callback:function ($$v) {_vm.$set(item, "schoolType", $$v)},expression:"item.schoolType"}}),_c('van-field-calendar',{attrs:{"calendarProps":{
              mode: 'during',
            },"fieldProps":{
              required: true,
              label: '学习起止时间',
              placeholder: '请输入出生年月日',
            },"required":""},model:{value:(item.timeRange),callback:function ($$v) {_vm.$set(item, "timeRange", $$v)},expression:"item.timeRange"}}),_c('van-field',{attrs:{"label":"学习学校","placeholder":"请输入学习学校","required":true,"maxlength":"100"},model:{value:(item.schoolName),callback:function ($$v) {_vm.$set(item, "schoolName", $$v)},expression:"item.schoolName"}}),_c('p',{staticClass:"fam-btns"},[_c('van-button',{staticClass:"btn",attrs:{"type":"default","size":"mini"},on:{"click":function($event){return _vm.onAddSchool()}}},[_vm._v("新增 ")]),_c('van-button',{staticClass:"btn",attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.onDeleteSchool(i)}}},[_vm._v("删除 ")])],1)],1)}),0),_c('div',{staticClass:"tip"},[_c('van-notice-bar',{attrs:{"color":"#1989fa","background":"#ecf9ff","text":"入学子女家庭成员"}})],1),_c('div',_vm._l((_vm.form.family),function(item,i){return _c('div',{key:i},[_c('van-field-select',{attrs:{"options":_vm.familyTies,"fieldProps":{
              label: '关系',
              required: true,
              placeholder: '请选择关系',
            }},model:{value:(item.familyTies),callback:function ($$v) {_vm.$set(item, "familyTies", $$v)},expression:"item.familyTies"}}),_c('van-field',{attrs:{"name":"familyName","label":"姓名","placeholder":"请输入姓名","required":true,"maxlength":"100"},model:{value:(item.familyName),callback:function ($$v) {_vm.$set(item, "familyName", $$v)},expression:"item.familyName"}}),_c('van-field-select',{attrs:{"options":_vm.idCardTypes,"fieldProps":{
              label: '证件类型',
              required: true,
              placeholder: '请选择证件类型',
            }},model:{value:(item.famIdCardType),callback:function ($$v) {_vm.$set(item, "famIdCardType", $$v)},expression:"item.famIdCardType"}}),_c('van-field',{attrs:{"name":"famIdCardNum","label":"身份证号","placeholder":"请输入身份证号","required":true,"maxlength":"100"},model:{value:(item.famIdCardNum),callback:function ($$v) {_vm.$set(item, "famIdCardNum", $$v)},expression:"item.famIdCardNum"}}),_c('van-field',{attrs:{"label":"工作单位","placeholder":"请输入工作单位","maxlength":"100"},model:{value:(item.workCmpy),callback:function ($$v) {_vm.$set(item, "workCmpy", $$v)},expression:"item.workCmpy"}}),_c('van-field',{attrs:{"label":"联系方式","placeholder":"请输入联系方式","maxlength":"100"},model:{value:(item.phone),callback:function ($$v) {_vm.$set(item, "phone", $$v)},expression:"item.phone"}}),_c('p',{staticClass:"fam-btns"},[_c('van-button',{staticClass:"btn",attrs:{"type":"default","size":"mini"},on:{"click":_vm.onAddFamily}},[_vm._v(" 新增 ")]),_c('van-button',{staticClass:"btn",attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.onDeleteFamily(i)}}},[_vm._v(" 删除 ")])],1)],1)}),0)],1)],1):_c('div',[_c('van-empty',{attrs:{"description":"暂无产业人才认证,无法办理"}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notices"},[_c('p',{staticClass:"notice-item"},[_vm._v("填写说明：")]),_c('p',{staticClass:"notice-item"},[_vm._v(" 1、“市内家庭详细住址”一栏，应注明家庭所在路、街、院、排、楼号、楼门号及门牌号，非本地户籍请填写居住证地址且居住证地址需为真实有效地址。 ")]),_c('p',{staticClass:"notice-item"},[_vm._v(" 2、填表后请确认信息真实准确无误，如出现虚假信息取消入学资格。 ")])])
}]

export { render, staticRenderFns }